import RestaurantBlock from "../../RestaurantBlock/View/RestaurantBlock.view";
import Zoldfutar from "../../../Resources/zoldfutar_gomb.png";
import HetiMenu from "../../../Resources/hetimenu_gomb.png";

export interface IMainPageViewProps {}

const MainPageView: React.FC<IMainPageViewProps> = (props) => {
    return (
        <div className="main-page">
            <RestaurantBlock
                backgournd_url={Zoldfutar}
                link="https://rendeles.westyzoldfutar.hu"
                className="align-right"
            />

            <RestaurantBlock
                backgournd_url={HetiMenu}
                link="https://ebed.westyzoldfutar.hu"
                className="align-left"
            />
        </div>
    );
};

export default MainPageView;
